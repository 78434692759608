import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import {BDivider, CircularProgress, Tooltip, Container} from '@mui/material';
import {CircularProgress, Box,Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,Stack, Container , IconButton, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Tooltip, Autocomplete, MenuItem} from '@mui/material';
import { Navigate, useNavigate, useParams} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import './dasboard.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import caisse1 from '../../images/lottie/caisse1.json'
import caisse2 from '../../images/lottie/caisse2.json'
import caisse3 from '../../images/lottie/caisse3.json'
import teamL from '../../images/lottie/teamL.json'
import userCompte from '../../images/lottie/UserCompte.json'


var BACKEND_URL = 'https://backend.bet-partners.com/api/';
var countData=1;


export default function ListClientsSection({id_user, type_user}) {
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [user,setUser] =useState([]);
    //var [type_userR,setType_UserR] = useState('')
    const [client, setClient] = useState([]);
    const [listBookmaker, setListBookmaker] = useState([]);

    const [backendErrorMessage, setBackendErrorMessage] = useState('');


    let [currentPage, setCurrentPage] =useState(1);    
    const dataPerPage = 10;
    const lastIndex = currentPage * dataPerPage;
    const firstIndex = lastIndex - dataPerPage;
    const data = client.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(client.length /dataPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    const maxPagesToShow = windowWidth>700?5:3;

    const getPageNumbersToShow = () => {
      const startPage = Math.max(1, currentPage - maxPagesToShow);
      const endPage = Math.min(nPage, currentPage + maxPagesToShow);
    
      return [...Array(endPage - startPage + 1).keys()].map((i) => startPage + i);
    };


    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const theme = useTheme();

    const [formData, setFormData] = useState({
      partenaire_id: id_user,
      nom: "",
      prenom: "",
      numero: "",
      bookmaker: "",
      statut_joueur: "Traitement",
      identifiantJoueur: "",
      date_enregistrement: "",
    });
  
    // Gérer les changements de chaque champ du formulaire
    const handleChangeAjout = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setBackendErrorMessage('');
    };
  

    const ajouterJoueur = async (formData) => {
      try {
        // Effectuer l'appel API avec axios
        const response = await axios.post(`${BACKEND_URL}joueurs/create`, formData);
        
        // Si la requête est réussie, afficher les données du joueur ajouté
        console.log("Joueur ajouté avec succès :", response.data);
        request();
        handleCloseDialog();
        return response.data;
      } catch (error) {
        // En cas d'erreur, afficher le message correspondant
          setBackendErrorMessage(error.response.data.error);       
          console.log(error.response.data.messages);
      }
    };

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const handleClickOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setBackendErrorMessage('')
        setOpenDialog(false);
      };
      

    const valider = async (id_joueur) => {
        try{
          await axios.put(`${BACKEND_URL}joueurs/validerJoueur/${id_joueur}`)
          .then(({data}) => {
              console.log(data);    
              request()          
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
              console.log(error.response.data.error);
          });
          
        } catch (error) {
          console.log(error)
        }
      }


      const request = async () => {
        try{

         /* await axios.get(`${BACKEND_URL}utilisateurs/show/${id_user}`)
    .then(({data}) => {
        console.log(data);
        setUser(data);
    }).catch ((error) =>
    {
        setBackendErrorMessage(error.response.data.error);
    });*/
    
console.log(type_user)
//setType_UserR(type_user);
    if(type_user=='Partenaire')
    {
          await axios.get(`${BACKEND_URL}joueurs/indexPartenaire/${id_user}`)
          .then(({data}) => {
              console.log(data);
              setClient(data);
              console.log(data)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });
        }
        else{
          await axios.get(`${BACKEND_URL}joueurs`)
          .then(({data}) => {
              console.log(data);
              setClient(data);
              console.log(data)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });
        }
        

          await axios.get(`${BACKEND_URL}bookmakers`)
          .then(({data}) => {
              console.log(data);
              setListBookmaker(data);
              console.log(data)
          }).catch ((error) =>
          {
              setBackendErrorMessage(error.response.data.error);
          });
          
        } catch (error) {
          console.log(error)
        }
      }

    useEffect(() => {
        request();
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
      <div>
        <Stack direction={'column'}
              alignItems='center'
              sx={{marginTop: 3,
                marginLeft: 2, 
                marginRight: 2, 
                borderRadius: 5, 
                minWidth:windowWidth-150,
                boxShadow: '0px 0px 5px #2B2A4C',
                backgroundColor:'white'}}>     
              <Stack sx={{fontWeight: 600, fontSize: 20, margin: 2}}>
              Liste des joueurs
            </Stack>                         
            <Stack>
            {/*<div style={{marginBottom: 20}}>
                    <TextField type="text" 
                          value={searchQuery} 
                          onChange={handleSearchChange} 
                          placeholder="Rechercher...">
                    </TextField>
                    </div>*/}
                    {windowWidth>700?
              <TableContainer component={Paper}>
                      <Table sx={{ minWidth:windowWidth-200, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={8}>Joueurs</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Numéro</TableCell>                                                                                                                                                     
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Prénom</TableCell> 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>                                                                                                                                 
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >ID-Joueur</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >BookMaker</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>   
                                      {type_user!='Partenaire'? <TableCell align="left" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell> 
                                      :null}                                         
                              </TableRow>
                                  {data.map((row, index) => (
                              <TableRow>
                                      <TableCell align="left" style={{fontWeight: 600}}>{countData+index}</TableCell>                                                                                                                                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.nom}</TableCell>     
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.prenom}</TableCell>                                        
                                      <TableCell align="left" style={{fontWeight: 600}}>{row.numero}</TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{row.identifiantJoueur} </TableCell>
                                      <TableCell align="left" style={{fontWeight: 600,}}>{ row.bookmaker} </TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>
                                        <Button style={{color:'white',
                                           backgroundColor:row.statut_joueur != 'Valider'? '#3A6D8C':'green',
                                           fontFamily:'monospace', padding:2, textTransform:'none'}}>
                                            {row.statut_joueur}
                                            </Button>

                                            <Button style={{color:'white',
                                           backgroundColor:row.payer != 'Régler'? '#3A6D8C':'#FABC3F',
                                           fontFamily:'monospace', padding:2,marginLeft:1, textTransform:'none'}}>
                                            {row.payer?'Régler':'Pas solder'}
                                            </Button>
                                             </TableCell>                                      
                                      {type_user!='Partenaire'? <TableCell align="left" style={{fontWeight: 600}}>   
                                            <Stack direction={'column'}
                                                spacing={{sm: 1}}
                                                alignItems={'center'}                                                   
                                            >
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}} >
                                                <Tooltip title='Valider'>
                                                  <IconButton style={{ color: row.statut_joueur != 'Valider'? 'green':'red' }} onClick={() => { valider(row.id) }} >
                                                    {row.statut_joueur != 'Valider'? <CheckCircleIcon />: <CancelIcon />}
                                                  </IconButton>
                                                </Tooltip>
                                                
                                                {/*
                                                <Tooltip title='Voir'>
                                                  <IconButton style={{ color: '#0D9276' }} onClick={() => { /*navigate(`/afficher-sortie/${row.id}`) }} >
                                                    <ArrowForwardIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {/*Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip> */}                                                                                            
                                                </Stack>
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}}>                                                                                                       
                                                </Stack>                                                    
                                            </Stack>                                             
                                                                                       
                                      </TableCell>:null}
                                              </TableRow>
                                                ))}
                                          </TableBody>
                      </Table>
              </TableContainer>
              :
              <TableContainer component={Paper}>
                      <Table sx={{ minWidth:windowWidth-100, }} aria-label="simple table">
                          <TableHead style={{backgroundColor: '#2B2A4C',}}>
                              <TableRow>
                                  <TableCell align="center" style={{color: 'white', fontWeight: 600, fontSize: 20}} colSpan={2}>Joueurs</TableCell>
                              </TableRow>
                          </TableHead>
                          {data.map((row, index) => (
                          <TableBody>
                              <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#227B94', color:'white'}} >ID-Joueur</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell align="center" colSpan={2} style={{fontWeight: 600}}>{row.identifiantJoueur}</TableCell>     
                                      
                                    </TableRow>
                                    <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Nom</TableCell> 
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Prénom</TableCell>                                                                                                                                 
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600}}>{row.nom}</TableCell>                                        
                                      <TableCell align="center" style={{fontWeight: 600}}>{row.prenom}</TableCell>
                                      
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Téléphone</TableCell>
                                      <TableCell align="center" style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Bookmaker</TableCell>
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.numero} </TableCell>
                                      <TableCell align="center" style={{fontWeight: 600,}}>{row.bookmaker} </TableCell>                                      
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Statut</TableCell>   
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600,
                                         color: row.statut_joueur != 'Valider'? 'red':'green'}}>{row.statut_joueur} </TableCell>                                                                            
                                      </TableRow>
                                      <TableRow>
                                      {type_user!='Partenaire'?  <TableCell align="center" colSpan={2} style={{fontWeight: 600, backgroundColor: '#F6F5F5'}} >Action</TableCell>  
                                      :null}                                        
                              </TableRow>                                  
                              {type_user!='Partenaire'? <TableRow>
                                      <TableCell align="center" colSpan={2} style={{fontWeight: 600}}>   
                                            <Stack direction={'column'}
                                                spacing={{sm: 1}}
                                                alignItems={'center'}                                                   
                                            >
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}} >
                                                <Tooltip title='Valider'>
                                                  <IconButton style={{  color: row.statut_joueur != 'Valider'? 'green':'red' }} onClick={() => { 
                                                    row.statut_joueur != 'Valider'? valider(row.id) : console.log('Deja valider') }} >
                                                    { row.statut_joueur!= 'Valider'?<CheckCircleIcon />:<CancelIcon />}
                                                  </IconButton>
                                                </Tooltip>
                                                {/*
                                                <Tooltip title='Voir'>
                                                  <IconButton style={{ color: '#0D9276' }} onClick={() => { /*navigate(`/afficher-sortie/${row.id}`) }} >
                                                    <ArrowForwardIcon />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Supprimer'>
                                                        <IconButton style={{ color: '#D24545' }} onClick={() => {/*Deleterequest(row.id)}} >
                                                          <DeleteIcon />
                                                        </IconButton>
                                                </Tooltip>*/}                                                                                           
                                                </Stack>
                                              <Stack direction={'row'}
                                                spacing={{sm: 0}}>                                                                                                       
                                                </Stack>                                                    
                                            </Stack>                                             
                                                                                       
                                      </TableCell>
                                              </TableRow>:null}
                                                
                                          </TableBody>
                                          ))}
                      </Table>
              </TableContainer>
                                              }
              <div class={windowWidth>700?"flex-row":"flex-column"} style={{margin: 10}}>
                  <nav > 
                      <ul className='pagination'>
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={prePage}>Préc</a>
                          </li>
                          {
                            getPageNumbersToShow().map((n,i) =>
                            (
                              <li className= {`page-item  ${currentPage=== n ? 'active' : '' }`} key={i}>
                                <a href='#' className='page-link'
                                onClick={() => changeCPage(n)}>{n}</a>
                              </li>
                            )
                            )
                          }
                          <li className='page-item'>
                            <a href='#' className='page-link'
                              onClick={nextPage}>Suiv</a>
                          </li>
                      </ul>
                  </nav>
                  <div>
                    <h5>Total {client.length}</h5> 
                  </div>
          </div>
            </Stack>
            <Button variant="contained" style={{margin: 20}} startIcon={<AddIcon />}   onClick={() => {handleClickOpenDialog()}} >
              Ajouter                      
            </Button>
            </Stack>
            <Dialog
                  //fullScreen={fullScreen}
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Information du joueur"}
                  </DialogTitle>
                  <DialogContent>
                    <Stack direction={'column'}
                        spacing={{sm: 2}}
                        sx={{marginTop: 1}}
                        >
                         {backendErrorMessage && <p style={{ color: 'red' }}>{backendErrorMessage}</p>} 
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Nom'
                                value={formData.nom}
                                name='nom'
                                style={{minWidth:250}}
                                onChange={handleChangeAjout}
                            ></TextField>
                             <TextField type='text' 
                                label='Prénom'
                                value={formData.prenom}
                                name='prenom'
                                style={{minWidth:250}}
                                onChange={handleChangeAjout}
                            ></TextField>
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                             <TextField type='text' 
                                label='ID-Joueur'
                                value={formData.identifiantJoueur}
                                name='identifiantJoueur'
                                style={{minWidth:250}}
                                onChange={handleChangeAjout}
                            ></TextField>
                            <TextField type='text' 
                                label='Téléphone'
                                value={formData.numero}
                                name='numero'
                                style={{minWidth:250}}
                                onChange={handleChangeAjout}
                            ></TextField>
                                                                                
                          </Stack>
                          <Stack
                              direction={windowWidth>700?'row':'column'}
                              spacing={{sm: 2}}
                              sx={{marginTop: 1}}
                          >
                            <TextField type='text' 
                                label='Selectionner un bookmaker'
                                value={formData.bookmaker}
                                style={{minWidth: 250 ,maxWidth: windowWidth>700? 250 : 220,marginTop: windowWidth>700 ? 0:5}}
                                select
                                name='bookmaker'
                                onChange={handleChangeAjout}
                            >
                                <MenuItem key={'1xbet'} value={'1xbet'}>
                                {'1xbet'}
                              </MenuItem>
                              <MenuItem key={'BetWinner'} value={'BetWinner'}>
                                {'BetWinner'}
                              </MenuItem>
                              <MenuItem key={'1Win'} value={'1Win'}>
                                {'1Win'}
                              </MenuItem>
                            </TextField>
                            <Stack direction={'column'}>                                
                                    <TextField type='date'                                         
                                        style={{minWidth: 250,maxWidth: windowWidth>700? 250 : 220,marginTop: windowWidth>700 ? 0:5}}
                                        value={formData.date_enregistrement}
                                        name='date_enregistrement'
                                        onChange={handleChangeAjout}
                                    ></TextField>
                            </Stack>                                
                          </Stack>
                            <Button variant="contained" endIcon={<CheckCircleIcon />} onClick={() => {ajouterJoueur(formData)}} style={{fontWeight: 600}}>
                              Valider 
                            </Button>                            
                    </Stack>

                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' color='error' onClick={handleCloseDialog} autoFocus>
                      Annuler
                    </Button>
                  </DialogActions>
                </Dialog>
      </div>
    )
     
    function nextPage(){
      if(firstIndex+10 < client.length)
      {
        setCurrentPage(currentPage + 1);
        countData=countData+10;
      }
    }
  
    function prePage(){
      if(firstIndex-1>0)
      {
        setCurrentPage(currentPage - 1);
        countData=countData-10;
        console.log(countData)
      }
    }
  
    function changeCPage(id){
      setCurrentPage(id);
      countData = ((id*10)-9)
    }
}