import logo from './logo.svg';
import './App.css';
import * as React from "react";
import {createBrowserRouter, RouterProvider } from "react-router-dom";
import Accueil from './pages/accueil';
import APropos from './pages/apropos';
import Service from './pages/service';
import Partenaire from './pages/partenaires';
import Inscription from './pages/inscription';
import Connexion from './pages/connexion';
import Dashboard from './pages/dashboard';
import { List } from '@mui/material';
import ListOperationsSection from './pages/composants/DashboardSection/ListOperationsSection';
import PaiementSection from './pages/composants/DashboardSection/paiementSection';
import ReglageSection from './pages/composants/DashboardSection/reglageSection';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Accueil />,
  },
  {
    path: "/a-propos",
    element: <APropos />,
  },
  {
    path: "/service",
    element: <Service />,
  },
  {
    path: "/partenaire",
    element: <Partenaire />,
  },
  {
    path: "/inscription",
    element: <Inscription />,
  },
  {
    path: "/connexion",
    element: <Connexion />,
  },
  {
    path: "/dashboard/:id_user/:type_user",
    element: <Dashboard />,
  },
  {
    path: "/dashboard/:id_user/:type_user/operations",
    element: <ListOperationsSection />,
  },
  {
    path: "/dashboard/:id_user/:type_user/paiements",
    element: <PaiementSection />,
  },
  {
    path: "/dashboard/:id_user/:type_user/reglage",
    element: <ReglageSection />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
