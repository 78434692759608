import React from 'react';
import { Box, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#19A7CE',
        color: 'white',
        p: { xs: 4, sm: 6 }, // Padding adaptatif pour mobile et desktop
        mt: 4,
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Section: À propos de nous */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom fontWeight={'bold'}>
            À propos de nous
          </Typography>
          <Typography variant="body2">
            Nous sommes une entreprise spécialisée dans les solutions technologiques et l'accompagnement des entreprises pour optimiser leurs performances et leur présence en ligne.
          </Typography>
        </Grid>

        {/* Section: Liens utiles */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom fontWeight={'bold'}>
            Liens utiles
          </Typography>
          <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
            Accueil
          </Link>
          <Link href="/service" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
            Services
          </Link>
          <Link href="/partenaire" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
            Devenir partenaire
          </Link>
          <Link href="/a-propos" color="inherit" underline="hover" sx={{ display: 'block' }}>
            À propos
          </Link>
        </Grid>

        {/* Section: Contact */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom fontWeight={'bold'}>
            Contact
          </Typography>
          <Typography variant="body2">
            Email : support@tep-service.com
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Téléphone : +226 77 17 02 02
          </Typography>
          <Box>
            {/* Icônes de réseaux sociaux */}
            <IconButton href="https://www.facebook.com" color="inherit" aria-label="Facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton href="https://www.twitter.com" color="inherit" aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
            <IconButton href="https://www.linkedin.com" color="inherit" aria-label="LinkedIn">
              <LinkedInIcon />
            </IconButton>
            <IconButton href="mailto:support@tep-service.com" color="inherit" aria-label="Email">
              <EmailIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Copyright */}
      <Box textAlign="center" sx={{ mt: 4, pt: 2, borderTop: '1px solid #444' }}>
        <Typography variant="body2" fontWeight={'bold'}>
          &copy; {new Date().getFullYear()} BET-Partners. Tous droits réservés.
        </Typography>
      </Box>
    </Box>
  );
}
