import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import iconIt from './iconIt.png';
import 'bootstrap/dist/js/bootstrap'
import './composants.css';
import {useNavigate } from "react-router-dom";

var BACKEND_URL = 'https://backend.bet-partners.com/api/';
//var type_user=''
export default function NavDash({id_user, type_user}) {

    const [activeLink, setActiveLink] = useState('');
    //const { id_user } = props;
    console.log(id_user,type_user)
    const [user,setUser] = useState();
    const [backendErrorMessage, setBackendErrorMessage]= useState([])

    
    const navigate = useNavigate();

    const handleLinkClick = (link) => {
        setActiveLink(link);
        // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
        // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
        navigate(`/${link}`)
    };

    const request = async () => {
      try{

  /*    await axios.get(`${BACKEND_URL}utilisateurs/show/${id_user}`)
  .then(({data}) => {
      console.log(data);
      setUser(data);
      type_user=data.type_user
  }).catch ((error) =>
  {
      setBackendErrorMessage(error.response.data.error);
  });
  */
        
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() =>  {
      try {
        request()
        console.log(id_user)
      } catch (error) {
        console.log(error)
      }

    });
    return (
        <div class='divFirst' style={{backgroundColor:'black'}}>
            <nav class="navbar navbar-expand-sm navbar-dark navbar-custom"
            style={{backgroundColor:'#19A7CE', color:'white'}}>
            <div class="container-fluid">
              <a class="navbar-brand text-nav-colors"
               //href="javascript:void(0)"
               role='button'
               style={{fontFamily:'monospace', color:'white'}}
               onClick={() => {navigate(`/dashboard/${id_user}/${type_user}`)}}>BET-Partners</a>
              <button class="navbar-toggler" style={{backgroundColor: '#0077ff'}} type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                <span class="navbar-toggler-icon" style={{backgroundColor: '#0077ff'}}></span>
              </button>
              <div class="collapse navbar-collapse" id="mynavbar">
                <ul class="navbar-nav me-auto">                
                {type_user!='Admin'?
                <>
                <li className={`nav-item`}>
                    <a class="nav-link text-nav-colors " style={{color:'white'}} role='button' onClick={() => {navigate(`operations/`)}}>Paiements</a>
                  </li>                  
                  </>
                  :
                  <>
                  <li className={`nav-item`}>
                    <a class="nav-link text-nav-colors " style={{color:'white'}} role='button' onClick={() => {navigate(`/dashboard/${id_user}/${type_user}/paiements`)}}>Paiements</a>
                  </li>
                  <li className={`nav-item`}>
                    <a class="nav-link text-nav-colors " style={{color:'white'}} role='button' onClick={() => {navigate(`/dashboard/${id_user}/${type_user}/reglage`)}}>Réglages</a>
                  </li>
                  </>
                  }                  
                </ul>                                
                <Button variant="contained" style={{margin: 2}}  onClick={() => {navigate(`/`)}} >
                    Déconnexion                      
                </Button>
              </div>
            </div>
              </nav>
              <script></script>
    </div>
    );
}