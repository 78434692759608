import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import Icon from '@mui/icons-material/Star'; // Importation d'une icône par exemple
import { minHeight, Stack, styled } from '@mui/system';
import Money from '../../images/manage.gif';
import Contrat from '../../images/agreement.gif';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'

const StyledCard = styled(Card)(({ theme }) => ({
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows,
  },
}));

const FourSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    

  return (
      <Grid container spacing={10}  sx={{
      //height: '100vh', // Utilise toute la hauteur de la page
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      //backgroundColor: '#f0f0f0', // Couleur de fond optionnelle
      padding: '10px',
    }}>
          <Grid item xs={12} sm={4}>
            <Card sx={{borderRadius:5,backgroundColor:'#fff5f5', marginTop:windowWidth>500?-15:0, padding:1,}}>
              <CardContent>
                <Stack direction={'column'} spacing={3}>
                <Box display="flex" alignItems="center">
                  <img src={Contrat} style={{height:55,width:55,borderRadius:13}} />
                </Box>
                <Typography variant="h6" style={{fontWeight:'bold', fontFamily:'monospace',
                   color:'#2F3645'}}>Création de Code Promo Personnalisé. </Typography>
                <Typography variant="body2" sx={{ fontSize:'16px',fontFamily:'unset', color:'#2F3645' }}>
                Permettant 
                de proposer des offres uniques et 
                ciblées pour attirer et fidéliser vos utilisateurs.
                </Typography>
                <Stack direction={'row'} alignSelf={'flex-end'} style={{marginTop:-5}}>
                <Lottie animationData={start} loop style={{
                            height:50,
                            width:50
                        }}/>
                </Stack>
                </Stack>
              </CardContent>
              </Card>              
          </Grid>
          <Grid item xs={12} sm={4}>
          <Card sx={{borderRadius:5,backgroundColor:'#f7f0fc', padding:1,}}>
              <CardContent>
                <Stack direction={'column'} spacing={3}>
                <Box display="flex" alignItems="center">
                  <img src={Money} style={{height:55,width:55,borderRadius:13}} />
                </Box>
                <Typography variant="h6" style={{fontWeight:'bold',
                   fontFamily:'monospace',
                  color:'#2F3645'}}>Création de Compte de Jeu. </Typography>
                <Typography variant="body2" sx={{ fontSize:'16px',fontFamily:'unset', color:'#2F3645' }}>
                Permet aux utilisateurs
                 de s'inscrire facilement sur les 
                plateforme de Paris.
                </Typography>
                <Stack direction={'row'} alignSelf={'flex-end'} style={{marginTop:-5}}>
                <Lottie animationData={start} loop style={{
                            height:50,
                            width:50
                        }}/>
                </Stack>
                </Stack>
              </CardContent>
              </Card>
          </Grid>
          
      </Grid>
  );
};

export default FourSection;
