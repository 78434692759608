import React, { useState, useEffect } from 'react';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import callCenter from '../../images/lottie/callCenter.json'




export default function FiveSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5">
            <div className="row align-items-center">

                {/* Partie gauche : contenu */}
                <div className="col-md-7" style={{marginRight:0}}>
                <Lottie animationData={callCenter} loop style={{
                            height:windowWidth>500? 600:300,
                            width:windowWidth>500? 600:300
                        }}/>
                </div>                                
                {/* Partie droite : image */}
                <div className="col-md-5">
                    <div className="card border-0" style={{ padding: '10px' }}>
                    
                        <p className="card-title" style={{fontWeight:'bold', fontSize:windowWidth>500? 30:20}} >
                        Notre équipe de service clientèle est disponible 24/7
                        </p>
                        <p className="card-text" style={{fontWeight:'', fontSize: windowWidth>500?  20:20}}>
                        Pour répondre à toutes vos questions et vous aider à chaque étape.
                          </p>
                          <p className="card-text" style={{fontWeight:'', fontSize: windowWidth>500?  20:20}}>
                          Faites partie dès maintenant de nos clients satisfaits et profitez de
                           l'assistance personnalisée dont vous avez besoin, quand vous en avez besoin.
                          </p>
                          <Stack direction={'row'} alignItems={'center'} spacing={2}>
                          
                          <Button variant="contained" style={{width:250, height:40,
                             backgroundColor:'#19A7CE',
                             color:'white',
                             fontWeight:'bold'}}>
                            Contactez-Nous
                          </Button>
                          <Lottie animationData={start} loop style={{
                            height:100,
                            width:100
                        }}/>

                          </Stack>
                    </div>
                </div>
            </div>
        </div>
    );
}
