import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Lottie from 'lottie-react';
import active from '../../images/lottie/active.json'
import inscription from '../../images/lottie/inscription.json'

// Étapes avec leur description
const steps = [
  {
    label: 'Inscription - 1ere Etape',
    description: `L'étape d'inscription à notre plateforme est simple et rapide.
     Il vous suffit de créer un compte en fournissant les informations de base, 
     telles que votre nom, votre adresse e-mail et un mot de passe sécurisé. 
     Une fois votre compte validé, vous pouvez personnaliser votre profil 
     et accéder à toutes les fonctionnalités pour 
    démarrer vos collaborations dans le secteur des paris sportifs.`,
  },
  {
    label: 'Activation du compte- 2eme Etape',
    description:
      `L'administrateur de l'application verifie vos informations et active votre compte
      s'il est eligible.`,
  },
  {
    label: 'Commencez à monetisez- 3eme Etape',
    description: `Commencez à monétiser en activant les paris sur votre profil partenaire.
     Cette étape vous permet de générer des revenus en tirant parti des 
     transactions effectuées par vos utilisateurs et de maximiser 
     vos gains grâce aux commissions.
     Profitez de chaque opportunité pour rentabiliser vos activités !`,
  },
];

// Images correspondant à chaque étape
const stepImages = [
  inscription,
  active,
  inscription
];

export default function CCMSection() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    

  return (
    <div className="container my-5">
            <div className="row align-items-center">
    <Typography variant="subtitle1" align="center" sx={{ mb: 1, color:'#19A7CE' }}>
        Facile, Rapide et Fiable
        </Typography>
    <Typography variant={windowWidth>500?"h4":"h6"} gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
        Comment Ca Marche
      </Typography>
    <Box >
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Derniere Etape</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {/* Utilisation d'un Grid pour afficher l'image à droite */}
              <Grid container spacing={2} alignItems="center">
                {/* Contenu de l'étape */}
                <Grid item xs={8}>
                  <Typography>{step.description}</Typography>
                  <Box sx={{ mb: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1, backgroundColor:'#19A7CE' }}
                    >
                      {index === steps.length - 1 ? 'Fini' : 'Continue'}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Retour
                    </Button>
                  </Box>
                </Grid>

                {/* Image correspondant à chaque étape */}
                {windowWidth>500?
                <Grid item xs={4}>
                <Lottie animationData={stepImages[index]} loop style={{
                            height:windowWidth>500?400:250,
                            width:windowWidth>500?400:250
                        }}/>
                </Grid>:<div></div>}
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      
      {/* Affichage une fois toutes les étapes terminées */}
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Toute les etapes sont terminées</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Recommencer
          </Button>
        </Paper>
      )}
    </Box>
    </div>
    </div>
  );
}
