import React, { useState, useEffect } from 'react';
import {Box, Button,Typography, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import go from '../../images/lottie/go.json'
import people from '../../images/people.jpg'
import CondiP from '../../images/condiP.png'




export default function SecondSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5">
            <div className="row align-items-center">
            <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold',
              fontFamily:'monospace'
             }}>
        Conditions pour etre partenaire de BET-Partners
      </Typography>
                <Stack direction={windowWidth>500?'row':'column'} height={500} >
                  <Stack direction={'column'} spacing={windowWidth>500?10:2} style={{//border:'2px solid black',
                  paddingTop:windowWidth>500?40:0,padding:windowWidth>500?0:10,
                     width:350}}>
                    <Stack style={{padding:windowWidth>500?10:0}}>
                      <div style={{backgroundColor:'#D3EE98',
                       padding:10,
                         width:45,
                         borderRadius:20,
                         textAlign:'center',
                         color:'#72BF78',
                         fontWeight:'bold',
                         fontFamily:'monospace',
                         fontSize:20 }}>01
                         </div>
                         <div style={{paddingLeft:windowWidth>500?20:10,
                          fontFamily:'monospace',
                           fontSize:18}}>
                         Avoir une entreprise<br />
                          <span style={{fontWeight:'bold'}}>officiellement enrégistrée.</span>
                         </div>
                    </Stack>
                    <Stack style={{paddingTop:windowWidth>500?10:2,paddingLeft:windowWidth>500?30:0}}>
                      <div style={{backgroundColor:'#D2E0FB',
                       padding:10,
                         width:45,
                         borderRadius:20,
                         textAlign:'center',
                         color:'blue',
                         fontWeight:'bold',
                         fontFamily:'monospace',
                         fontSize:20 }}>02
                         </div>
                         <div style={{paddingLeft:windowWidth>500?20:0,
                          fontFamily:'monospace',
                           fontSize:18}}>
                         Faire des transactions à hauteur d'un volume moyen de <br />
                          <span style={{fontWeight:'bold'}}>2000 dollars ou plus par semaine.</span>
                         </div>
                    </Stack>
                  </Stack>
                  {windowWidth>500?<Stack direction={'column'} style={{//border:'2px solid black',
                     width:400,}}>
                    <img src={CondiP} width={400} height={450} />
                  </Stack>
                  :
                  <Box></Box>}
                  <Stack direction={'column'} spacing={windowWidth>500?10:2} style={{//border:'2px solid black',
                  paddingTop:windowWidth>500?40:0,padding:windowWidth>500?0:10,
                     width:350}}>                  
                    <Stack style={{paddingTop:windowWidth>500?10:2,paddingLeft:windowWidth>500?30:0}}>
                      <div style={{backgroundColor:'#D2E0FB',
                       padding:10,
                         width:45,
                         borderRadius:20,
                         textAlign:'center',
                         color:'blue',
                         fontWeight:'bold',
                         fontFamily:'monospace',
                         fontSize:20 }}>03
                         </div>
                         <div style={{paddingLeft:windowWidth>500?20:0,
                          fontFamily:'monospace',
                           fontSize:18}}>
                         Faire des transactions à hauteur d'un volume moyen de <br />
                          <span style={{fontWeight:'bold'}}>2000 dollars ou plus par semaine.</span>
                         </div>
                    </Stack>
                    <Stack style={{padding:windowWidth>500?10:0}}>
                      <div style={{backgroundColor:'#D3EE98',
                       padding:10,
                         width:45,
                         borderRadius:20,
                         textAlign:'center',
                         color:'#72BF78',
                         fontWeight:'bold',
                         fontFamily:'monospace',
                         fontSize:20 }}>04
                         </div>
                         <div style={{paddingLeft:windowWidth>500?20:0,
                          fontFamily:'monospace',
                           fontSize:18}}>
                         Avoir une entreprise<br />
                          <span style={{fontWeight:'bold'}}>officiellement enrégistrée.</span>
                         </div>
                    </Stack>
                  </Stack>
                </Stack>
            </div>
        </div>
    );
}
