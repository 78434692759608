import React, { useState,useEffect } from 'react';
import axios from 'axios';
//import 'bootstrap/dist/css/bootstrap.css';
import * as ReactDOM from "react-dom/client";
import {Box,Grid, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, CircularProgress ,Stack, Tooltip, Container, Snackbar, Alert, Dialog} from '@mui/material';
import {createBrowserRouter, Route, RouterProvider, useNavigate } from "react-router-dom";
import Lottie from 'lottie-react';
import Nav from './composants/nav';
import Inscri from './images/lottie/inscriL.json';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from './composants/footer';
import FooterCoIns from './composants/footerCoIns';


var BACKEND_URL = 'https://backend.bet-partners.com/api/';



export default function Inscription() {

    const [load,setLoad]= useState();
    const [formData, setFormData] = useState({
      email: '',
      numero: '',
      pays: '',
      pref_paie: '',
      ref_paie: '',
      genre: '',
      password: '',
      confirmPassword: '',
      code_parrainage: '',
      type_user: 'Partenaire',
    });
  
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Options: 'success', 'error', 'warning', 'info'
    
  
    // Gestion de l'état pour chaque champ du formulaire
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    // Fonction pour soumettre le formulaire
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      // Validation du mot de passe
      if (formData.password !== formData.confirmPassword) {
        setSnackbarMessage("Les mots de passe ne correspondent pas.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
        return;
      }
  
      // Envoi des données vers l'API backend
      try {
        const response = await axios.post( `${BACKEND_URL}utilisateurs/create`, {
          email: formData.email,
          numero: formData.numero,
          pays: formData.pays,
          pref_paie: formData.pref_paie,
          ref_paie: formData.ref_paie,
          genre: formData.genre,
          password: formData.password,
          code_parrainage: formData.code_parrainage,
          type_user: formData.type_user,
        });
  
      setSnackbarMessage(`Utilisateur créé avec succès : ${response.data.email}`);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
        setFormData({
          email: '',
          numero: '',
          pays: '',
          pref_paie: '',
          ref_paie: '',
          genre: '',
          password: '',
          confirmPassword: '',
          code_parrainage: '',
          type_user: 'Partenaire',
        });
        
        window.location.href = '/connexion';
      } catch (error) {
        if (error.response && error.response.data.error) {
          setSnackbarMessage(`Erreur: ${JSON.stringify(error.response.data.error)}`);
        } else {
          setSnackbarMessage('Une erreur est survenue lors de la création de l’utilisateur.');
        }
        setSnackbarSeverity('error');
      setOpenSnackbar(true);
      }
    };

    const handleCloseSnackbar = () => {
      setOpenSnackbar(false);
    };
  

    const navigate = useNavigate();
  const handleLinkClick = (link) => {
    // Ajoutez la logique pour afficher la page correspondante en fonction du lien cliqué
    // Vous pouvez utiliser une bibliothèque de routage ou gérer manuellement le contenu ici
    navigate(`/${link}`)
};

   
  // Gérer la soumission du formulaire
  
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
   
    return (   
      <div>
        <Nav />
           
        <div class="container-fluid">
            <div class="row">
           
                <div class="col-sm-6" style={{ display: 'flex',
                 justifyContent: 'center',
                  alignItems: 'center',
                  //border:'2px solid black',
                   height: '100vh' }}>
                    {/* Colonne de gauche (Formulaire) */}
                    
                    <Box
      sx={{
        p: windowWidth>500?5:0,
        pt:windowWidth>500?5:5,
        pl:windowWidth>500?0:7,        
        maxWidth: 500,
        margin: 'auto',
        //backgroundColor: '#f5f5f5',
        borderRadius: 3,
        //boxShadow: 3,
        alignItems: 'center'
      }}
    >
      <Typography sx={{fontSize:windowWidth>500?20:15,      
       textAlign: 'left',
      fontFamily:'monospace',
      maxWidth:180,
      fontWeight:windowWidth>500?'':'bold',
         mb: 2 }}>
        Inscrivez-Vous
      </Typography>
      <Typography sx={{fontSize:15,      
       textAlign: 'left',
      maxWidth:350,
         mb: 2 }}>
        Rejoignez la plus grande plateforme de partenaires de paris sportifs
      </Typography>

      <Grid container spacing={3}>
        {/* E-mail et Numéro de téléphone */}
        <Grid item xs={windowWidth>500?12:10} sm={6}>
          <TextField
            fullWidth
            label="E-mail"
            type="email"
            name='email'
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            required
          />
        </Grid>

        <Grid item xs={windowWidth>500?12:10} sm={6}>
          <TextField
            fullWidth
            label="Numéro de téléphone"
            type="tel"
            variant="outlined"
            name='numero'
            value={formData.numero}
            onChange={handleChange}
            required
          />
        </Grid>

        {/* Pays de résidence */}



        <Grid item xs={windowWidth>500?12:10} sm={6}>
          
          <InputLabel>Pays de résidence</InputLabel>
          <Select defaultValue=""
          fullWidth 
          value={formData.pays}
          name='pays'
           onChange={handleChange}>
            <MenuItem value="">Sélectionner un pays</MenuItem>
            <MenuItem value="burkina">Burkina Faso</MenuItem>
            <MenuItem value="ivory-coast">Côte d'Ivoire</MenuItem>
            <MenuItem value="senegal">Sénégal</MenuItem>
            <MenuItem value="mali">Mali</MenuItem>
            <MenuItem value="ghana">Ghana</MenuItem>
          </Select>
        
      </Grid>


    <Grid item xs={windowWidth>500?12:10} sm={6}>
      
        <InputLabel>Genre</InputLabel>
        <Select defaultValue="" 
        fullWidth
        value={formData.genre}
        name='genre'
         onChange={handleChange}>
          <MenuItem value="">Sélectionner</MenuItem>
          <MenuItem value="Femme">Femme</MenuItem>
          <MenuItem value="Homme">Homme</MenuItem>
         </Select>          
    </Grid>

        <Grid item xs={windowWidth>500?12:10} sm={6}>
          
        <InputLabel>Référence Paiement</InputLabel>            
        <TextField
            fullWidth
            type="text"
            variant="outlined"
            name='ref_paie'
            value={formData.ref_paie}
            onChange={handleChange}
          />

      </Grid>
        
        {/* Détails de paiement */}
        <Grid item xs={windowWidth>500?12:10} sm={6}>
          
            <InputLabel>Détails de paiement</InputLabel>
            <Select defaultValue="" 
            fullWidth
            value={formData.pref_paie}
            name='pref_paie'
             onChange={handleChange}>
              <MenuItem value="">Sélectionner une option</MenuItem>
              <MenuItem value="mobile-money">Mobile Money</MenuItem>
              <MenuItem value="credit-card">Carte de crédit</MenuItem>
              <MenuItem value="bank-transfer">Virement bancaire</MenuItem>
            </Select>          
        </Grid>

      {/* Détails de paiement */}

        {/* Mot de passe et Confirmation du mot de passe */}
        <Grid item xs={windowWidth>500?12:10} sm={6}>
          <TextField
            fullWidth
            label="Votre mot de passe"
            type="password"
            name='password'
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={windowWidth>500?12:10} sm={6}>
          <TextField
            fullWidth
            label="Confirmation du mot de passe"
            type="password"
            name='confirmPassword'
            variant="outlined"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </Grid>

        {/* Code de parrainage */}
        <Grid item xs={windowWidth>500?12:10}>
          <TextField
            fullWidth
            label="Code de parrainage (facultatif)"
            type="text"
            name='code_parrainage'
            variant="outlined"
            value={formData.code_parrainage}
            onChange={handleChange}
          />
        </Grid>

        {/* Bouton d'inscription */}
        <Grid item xs={windowWidth>500?12:10}>
        <Button variant="contained" fullWidth style={{backgroundColor:'#19A7CE',
            padding: 5,
               fontSize:20,
               borderRadius:7,
            fontFamily:'monospace',
            textTransform: 'none',
            fontWeight:'bold',        
               color:'white'}}
               onClick={handleSubmit}
               >
              S'inscrire
            </Button>

            <Box mt={2} textAlign="center">
            <Typography variant="body1">
            Vous êtes déjà inscrit ? <Button href="/connexion" variant="text" color="primary">Connectez-vous</Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Dialog>
    </Box>  
      </div>
      {windowWidth>500?
                <div class="col-sm-6" style={{backgroundColor:'white',
               height: '100vh',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center'}}>
                    {/* Colonne de droite (Image) */}
                    {/* Contenu de votre conteneur ici */}
                    <Lottie animationData={Inscri} loop style={{
                            height:windowWidth>500? 450:350,
                            width:windowWidth>500? 450:350
                        }}/>                                        
                </div>
                :
                <div></div>
                      }
            </div>
    </div>
    <FooterCoIns />
    </div>
);
}
/*else
{
    return(
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // 100% de la hauteur de la vue
        }}
      >
        <Stack direction={'column'} alignItems={'center'} spacing={1}>
            <CircularProgress />
            <p style={{color: '#387ADF', fontWeight: 600}}>Chargement</p>
        </Stack>
      </Box>
    );
}
}*/
