import React, { useState, useEffect } from 'react';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import mobileWallet from '../../images/lottie/mobileWallet.json'





export default function FourSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5" style={{
            backgroundColor:'#19A7CE',
            borderRadius:'30px',
            padding:20,
            margin: ''
        }}>
            <div className="row align-items-center">
                {/* Partie gauche : contenu */}
                <div className="col-md-6">
                    <div className="card border-0" style={{ padding: '10px', backgroundColor:'#19A7CE' }}>                    
                        <p className="card-title" style={{fontWeight:'bold',color:'white', fontSize:windowWidth>500? 27:20}} >
                        Gagnez beaucoup d'argent grace au <span style={{fontSize:30,
                            fontFamily:'monospace',
                            color:'red'}}>Tirage au Sort</span> 
                        </p>
                        <p className="card-text" style={{fontWeight:'',color:'white', fontSize: windowWidth>500?  20:15}}>
                        Ce tirage au sort se fait chaque mois,
                        en fonction de touts les utilisateurs inscrit.
                          </p>
                          <Stack direction={'row'} alignItems={'center'} spacing={2}>
                          <Lottie animationData={start} loop style={{
                            height:100,
                            width:100
                        }}/>
                          <Button variant="contained" href='/inscription' style={{width:250, height:40,
                             backgroundColor:'white',
                             color:'#19A7CE',
                             fontWeight:'bold'}}>
                            Inscrivez-vous
                          </Button>

                          </Stack>
                          
                    </div>
                </div>
                
                {/* Partie droite : image */}
                <div className="col-md-6">
                <Lottie animationData={mobileWallet} loop style={{
                            height:windowWidth>500? 500:350,
                            width:windowWidth>500? 500:350
                        }}/>
                </div>
            </div>
        </div>
    );
}
