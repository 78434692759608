import React, { useState, useEffect } from 'react';
import {Box,Card, Grid,CardContent, Button,Typography, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { CheckCircle, TrendingUp, EmojiObjects } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import go from '../../images/lottie/go.json'
import people from '../../images/people.jpg'
import CondiP from '../../images/condiP.png'




export default function ThirdSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5" style={{marginTop: windowWidth>500?0:6}}>
            <div className="row align-items-center">
            <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold',
              fontFamily:'monospace'
             }}>
        Avantages d'etre partenaire
      </Typography>
      <Box sx={{ flexGrow: 1, p: 5, }}>
      <Grid container spacing={4} justifyContent="center">
        
        {/* Premier élément */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ minHeight: 250, textAlign: 'left' }}>
            <CardContent>
              <CheckCircle color="primary" sx={{ fontSize: 50, mb: 2 }} />
              <Typography variant="h5" component="div" gutterBottom
              style={{fontFamily:'monospace'}}>
                Quality Assurance
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We guarantee high-quality standards and dedicated support to ensure the best performance.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Deuxième élément */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ minHeight: 250, textAlign: 'left' }}>
            <CardContent>
              <TrendingUp color="secondary" sx={{ fontSize: 50, mb: 2 }} />
              <Typography variant="h5" component="div" gutterBottom
              style={{fontFamily:'monospace'}}>
                Business Growth
              </Typography>
              <Typography variant="body2" color="text.secondary">
                With our strategies and solutions, we help you scale your business to the next level.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Troisième élément */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ minHeight: 250, textAlign: 'left' }}>
            <CardContent>
              <EmojiObjects color="success" sx={{ fontSize: 50, mb: 2 }} />
              <Typography variant="h5" component="div" gutterBottom 
              style={{fontFamily:'monospace'}}>
                Innovative Ideas
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Our team is always thinking outside the box to provide you with the most innovative solutions.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </Box>
            </div>
        </div>
    );
}
