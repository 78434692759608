import React from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import melbet from '../../images/melbet.png'
import xbet from '../../images/1xbet.png'
import betwin from '../../images/betwin.png'

// Exemple d'images de partenaires (remplacez par les vôtres)
const partnerLogos = [
  { name: 'MelBet', imgSrc: melbet },
  { name: '1xbet', imgSrc: xbet },
  { name: 'BetWinner', imgSrc: betwin },
  { name: 'Partenaire 4', imgSrc: xbet },
  { name: 'Partenaire 5', imgSrc: melbet },
];

const PartenaireSection = () => {
  return (
    <Box sx={{ padding: '50px 10%', backgroundColor: '#f8f9fa' }}>
        <Typography variant="subtitle1" align="center" sx={{ mb: 1, color:'#19A7CE' }}>
        BET-Partners
        </Typography>

      {/* Titre de la section */}
      <Typography variant="h5" gutterBottom align="center" sx={{mb:5, fontWeight: 'bold' }}>
        Des partenaires pas comme les autres mais comme VOUS !!!
      </Typography>
      
      {/* Grille des cartes de partenaires */}
      <Grid container spacing={4} justifyContent="center">
        {partnerLogos.map((partner, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card sx={{ 
              ':hover': { 
                boxShadow: 6, // Augmente l'ombre au survol pour un effet dynamique 
                transform: 'scale(1.05)' // Zoom léger au survol
              }, 
              transition: 'transform 0.3s ease-in-out' 
            }}>
              <CardMedia
                component="img"
                height="140"
                image={partner.imgSrc} // Image du partenaire
                alt={partner.name}
                sx={{ padding: 2, objectFit: 'contain' }}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div" align="center">
                  {partner.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PartenaireSection;
